import { I18N } from 'aurelia-i18n';

export class DateFormatFullDayValueConverter {
  private i18n: I18N

  toView(value, locale) {
    try {

      let options = { weekday: 'short' as const, day: '2-digit' as const, month: 'short' as const, hour: '2-digit' as const, minute: '2-digit' as const };
      let formattedDate = new Intl.DateTimeFormat(locale, options).format(new Date(value));
      
      return formattedDate;
    }
    catch(e) {
      console.log(e);
      return value;
    }
  }
}

export class DateFormatHourOnlyValueConverter {
  private i18n: I18N

  toView(value, locale) {
    try {

      let options = { weekday: 'short' as const, day: '2-digit' as const, month: 'short' as const, hour: '2-digit' as const, minute: '2-digit' as const };
      let formattedTime = new Intl.DateTimeFormat(locale, {hour: 'numeric', minute: 'numeric'}).format(new Date(value));
      
      return formattedTime;
    }
    catch(e) {
      console.log(e);
      return value;
    }
  }
}
